import { DateTime, Duration } from 'luxon'
import { paths } from '@/constants/paths'
import { publisher, releasedEvent } from '@/constants/seo'
import { ContentRating, Episode, Genre, Movie, TVSeason, TVSeries, VideoObject } from '@/layout/Seo/JsonLd/types'
import { buildBrandedCloudinaryImageTransforms, getMediaMetaData } from '@/layout/Seo/SeoHelper'
import { ProjectMeta, ProjectMetadata, VideoMetaData } from '@/services/ProjectsService'
import { buildLinkToEpisode } from '@/utils/EpisodeUtil'
import { useProjectMetaTitle } from '@/utils/SpecialProjectUtils'
import { getBasePath } from '@/utils/sitemap/base'
import { NextSeoPropsWithRequiredFields } from '..'

function buildProjectUrl({ locale, slug }: { locale: string; slug: string }) {
  return `${getBasePath(locale)}/watch/${slug}`
}

export function useMetaForProject(project: ProjectMeta & { locale: string }): NextSeoPropsWithRequiredFields {
  const url = buildProjectUrl(project)
  const { title } = useProjectMetaTitle(project)

  return getMediaMetaData({
    url,
    cloudinaryImagePath: project.discoveryPosterLandscapeCloudinaryPath,
    cloudinaryTransformation: buildBrandedCloudinaryImageTransforms({ logoCloudinaryPath: project.logoCloudinaryPath }),
    description: project.showMetaDescription ?? project.theaterDescription,
    title,
    openGraph: {
      title,
      url,
    },
    type: 'tv_show',
  })
}

export function getVideoObjects(videos?: VideoMetaData[]): VideoObject[] {
  return (
    videos?.map((video) => {
      return {
        type: 'VideoObject',
        contentUrl: video.source.url,
        description: video.subtitle,
        duration: video.source.duration
          ? Duration.fromDurationLike({ hour: 0, minute: 0, second: video.source.duration }).normalize().toISO()
          : undefined,
        name: video.title,
        thumbnailUrl: `https://images.angelstudios.com/image/upload/b_rgb:000000,c_fill,h_630,w_1200,g_north/${video.posterLandscapeCloudinaryPath}`,
        uploadDate: '2021-03-25T12:00:00Z', // TODO We currently do not have an uploadDate field for Videos
      }
    }) || []
  )
}

export function getJsonLdForPodcastSeries(project: ProjectMeta & { locale: string }): TVSeries {
  const releaseDate = DateTime.fromISO(project.seasons[0]?.episodes[0]?.releaseDate).toISODate()

  return {
    about: {
      type: 'Thing',
      description: project.showMetaDescription || project.showOverview || project.theaterDescription,
    },
    contentRating: (project.metadata?.contentRating as ContentRating) || 'NR',
    dateCreated: releaseDate,
    description: project.theaterDescription,
    image: `https://images.angelstudios.com/image/upload/b_rgb:000000,c_fill,h_630,w_1200,g_north/${buildBrandedCloudinaryImageTransforms(
      { logoCloudinaryPath: project.logoCloudinaryPath, logoGravity: 'g_south_west', logoWidth: 0.25 },
    )}/${project.discoveryPosterLandscapeCloudinaryPath}`,
    name: project.name,
    publisher,
    releasedEvent,
    sameAs: project.metadata?.externalLinks,
    startDate: releaseDate,
    trailer: getVideoObjects(project.videos),
    url: buildProjectUrl(project),
  }
}

export function getJsonLdForTVSeries(project: ProjectMeta & { locale: string }): TVSeries {
  let seriesNumberOfEpisodes = 0
  const releaseDate = DateTime.fromISO(project.seasons[0]?.episodes[0]?.releaseDate).toISODate()

  const seasons = project?.seasons?.map((season) => {
    seriesNumberOfEpisodes += season?.episodes?.length

    const episodes = season.episodes?.map((episode) => {
      return {
        description: episode.description,
        episodeNumber: episode.episodeNumber,
        name: episode.subtitle,
        url: `${paths.base}${buildLinkToEpisode({
          projectSlug: project.slug,
          seasonNumber: season.seasonNumber,
          episodeNumber: episode.episodeNumber,
          guid: episode.guid,
          subtitle: episode.subtitle,
        })}`,
      } as Episode
    })

    return {
      type: 'TVSeason',
      episode: episodes,
      name: season?.name,
      numberOfEpisodes: episodes?.length,
      seasonNumber: season?.seasonNumber,
    } as TVSeason
  })

  return {
    about: {
      type: 'Thing',
      description: project.showMetaDescription || project.showOverview || project.theaterDescription,
    },
    containsSeason: seasons,
    contentRating: (project.metadata?.contentRating as ContentRating) || 'NR',
    dateCreated: releaseDate,
    description: project.theaterDescription,
    image: `https://images.angelstudios.com/image/upload/b_rgb:000000,c_fill,h_630,w_1200,g_north/${buildBrandedCloudinaryImageTransforms(
      { logoCloudinaryPath: project.logoCloudinaryPath, logoGravity: 'g_south_west', logoWidth: 0.25 },
    )}/${project.discoveryPosterLandscapeCloudinaryPath}`,
    name: project.name,
    numberOfEpisodes: seriesNumberOfEpisodes,
    numberOfSeasons: seasons?.length,
    publisher,
    releasedEvent,
    sameAs: project.metadata?.externalLinks,
    startDate: releaseDate,
    trailer: getVideoObjects(project.videos),
    url: buildProjectUrl(project),
  }
}

interface JsonLdForMovieProps {
  logoCloudinaryPath: string
  discoveryPosterLandscapeCloudinaryPath: string
  name: string
  slug: string
  showMetaDescription?: string
  showOverview?: string
  theaterDescription?: string
  videos?: VideoMetaData[]
  metadata?: ProjectMetadata
  seasons?: {
    episodes?: {
      releaseDate?: string
    }[]
  }[]
}

export function getJsonLdForMovie(project: JsonLdForMovieProps & { locale: string }): Movie {
  return {
    about: {
      type: 'Thing',
      description: project.showMetaDescription || project.showOverview || project.theaterDescription,
    },
    contentRating: (project.metadata?.contentRating as ContentRating) || 'NR',
    dateCreated: project.seasons?.[0]?.episodes?.[0]?.releaseDate
      ? DateTime.fromISO(project.seasons[0].episodes[0].releaseDate).toISODate()
      : '2021-03-25T12:00:00Z',
    description: project.theaterDescription,
    genre: (project.metadata?.genres as Genre[]) ?? [],
    image: `https://images.angelstudios.com/image/upload/b_rgb:000000,c_fill,h_630,w_1200,g_north/${buildBrandedCloudinaryImageTransforms(
      { logoCloudinaryPath: project.logoCloudinaryPath, logoGravity: 'g_south_west', logoWidth: 0.25 },
    )}/${project.discoveryPosterLandscapeCloudinaryPath}`,
    name: project.name,
    publisher,
    releasedEvent,
    sameAs: project.metadata?.externalLinks ?? [],
    trailer: getVideoObjects(project.videos),
    url: buildProjectUrl(project),
  }
}
